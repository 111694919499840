import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField
 
} from "@material-ui/core/"
import { styled } from "@material-ui/styles"

const MyButton = styled(Button)({
  backgroundColor: "#12652f",
  "&:hover": {
    background: "#218838",
  },
})
export class FormPersonalDetails extends Component {

   areFieldsInvalid (values){
    if (
      !values.firstName || !values.lastName || !values.salutation
    )
    return true;
    return false;
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  }


  
  back = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  render() {
    const { values, handleChange } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Kontaktdaten</h1>
            <p>
              Geben Sie Ihre Kontaktdaten ein, damit Sie umgehend die
              Informationen zur geeigneten Liegenschaft erhalten.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <FormControl fullWidth>
              <InputLabel>Anrede *</InputLabel>
              <Select
                native
                value={values.salutation}
                onChange={handleChange("salutation")}
              >
                <option value="" />
                <option value={"Herr"}>Herr</option>
                <option value={"Frau"}>Frau</option>
                <option value={"Keine Angabe"}>Keine Angabe</option>
              </Select>
              {/* <FormHelperText>Auto width</FormHelperText> */}
            </FormControl>
          </Col>
          <Col md={4} className="mb-3">
            <TextField
              fullWidth
              placeholder="Vorname"
              label="Vorname *"
              onChange={handleChange("firstName")}
              defaultValue={values.firstName}
            />
          </Col>
          <Col md={4} className="mb-3">
            <TextField
              fullWidth
              placeholder="Nachname"
              label="Nachname *"
              onChange={handleChange("lastName")}
              defaultValue={values.lastName}
            />
          </Col>

          <Col md={4} className="mb-3">
            <TextField
              fullWidth
              placeholder="PLZ"
              label="PLZ"
              onChange={handleChange("PostalCode")}
              defaultValue={values.PostalCode}
            />
          </Col>
          <Col md={4} className="mb-3">
            <TextField
              fullWidth
              value={values.city}
              placeholder="Stadt"
              label="Stadt"
              onChange={handleChange("city")}
              defaultValue={values.city}
            />
          </Col>
          <Col md={4} className="mb-3">
            <TextField
              fullWidth
              placeholder="Straße & Hausnummer"
              label="Straße"
              onChange={handleChange("street")}
              defaultValue={values.street}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md={6} className="align-self-end">
            <Button
              variant="contained"
              style={styles.button}
              onClick={this.back}
            >
              Zurück
            </Button>
            <MyButton
              disabled ={this.areFieldsInvalid(values)}
              color="primary"
              variant="contained"
              style={styles.button}
              onClick={this.continue}
            >
              Weiter
            </MyButton>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const styles = {
  button: {
    margin: 15,
  },
}

export default FormPersonalDetails
