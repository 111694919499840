import React, { Component } from "react"

import { Row, Col } from "react-bootstrap"
import TextField from "@material-ui/core/TextField"
import { styled } from "@material-ui/styles"
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Input,
  Slider,
  Typography,
} from "@material-ui/core/"
const MySlider = styled(Slider)({
  color: "#12652f",
})

const MyButton = styled(Button)({
  backgroundColor: "#12652f",
  "&:hover": {
    background: "#218838",
  },
})
export class FormUserDetails extends Component {

  
  areFieldsInvalid (values){
    if (
      !values.objectKPM || !values.objectType 
    )
    return true;
    return false;
  }

  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  }

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  }
  render() {
    const { values, handleChange, handleSliderChange } = this.props

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Suchkriterien Objekt</h1>
            <p>
              Wollen Sie eine Agrarimmobilie Kaufen oder Pachten ? Geben Sie die
              gewünschte Größe der Fläche (Hektar oder m²) an und wie viel Sie
              bereit sind dafür zu bezahlen.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-5">
            <FormControl fullWidth>
              <InputLabel>Kaufen / Pachten / Mieten *</InputLabel>
              <Select
                native
                value={values.objectKPM}
                onChange={handleChange("objectKPM")}
              >
                <option value="" />
                <option value={"Kaufen"}>Kaufen</option>
                <option value={"Pachten"}>Pachten</option>
                <option value={"Mieten"}>Mieten</option>
              </Select>
              {/* <FormHelperText>Auto width</FormHelperText> */}
            </FormControl>
          </Col>
          <Col md={6} className="mb-5">
            <FormControl fullWidth>
              <InputLabel>Objekttyp *</InputLabel>
              <Select
                native
                value={values.objectType}
                onChange={handleChange("objectType")}
                fullWidth
              >
                <option value="" />

                <option value="Ackerland">Ackerland</option>
                <option value="Wald">Wald</option>
                <option value="Betrieb">Betrieb</option>
                <option value="Hofstelle">Hofstelle</option>
                <option value="Anwesen">Anwesen</option>
                <option value="">Sonstige landwirtschaftl. Immobilien</option>
              </Select>
              {/* <FormHelperText>Auto width</FormHelperText> */}
            </FormControl>
          </Col>
          <Col md={6} className="mb-5">
            <FormControl fullWidth>
              <InputLabel>Preis</InputLabel>
              <Select
                native
                value={values.price}
                onChange={handleChange("price")}
                fullWidth
              >
                <option value="" />
                <option value="bis 0,5 Mio €">bis 0,5 Mio €</option>
                <option value="bis 1,0 Mio €">bis 1,0 Mio €</option>
                <option value="bis 2,0 Mio €">bis 2,0 Mio €</option>
                <option value="bis 3,0 Mio €">bis 3,0 Mio €</option>
                <option value="bis 4,0 Mio €">bis 4,0 Mio €</option>
                <option value="bis 7,5 Mio €">bis 7,5 Mio €</option>
                <option value="bis 10,0 Mio €">bis 10,0 Mio €</option>
                <option value="bis 12,5 Mio €">bis 12,5 Mio €</option>
                <option value="bis 15,0 Mio €">bis 15,0 Mio €</option>
                <option value="bis 20,0 Mio €">bis 20,0 Mio €</option>
                <option value="über 20,0 Mio €">über 20,0 Mio €</option>
                Sonstige landwirtschaftl. Immobilien
              </Select>
            </FormControl>
          </Col>
          <Col md={6} className="mb-5">
            <FormControl fullWidth>
              <InputLabel>Fläche</InputLabel>
              <Select
                native
                value={values.area}
                onChange={handleChange("area")}
                fullWidth
              >
                <option value="" />
                <option value="1 - 5 ha">1 - 5 ha</option>
                <option value="5 - 10 ha">5 - 10 ha</option>
                <option value="10 - 15 ha">10 - 15 ha</option>
                <option value="20 - 25 ha">20 - 25 ha</option>
                <option value="25 - 30 ha">25 - 30 ha</option>
                <option value="30 - 40 ha">30 - 40 ha</option>
                <option value="0 - 50 ha">40 - 50 ha</option>
                <option value="50 - 100 ha">50 - 100 ha</option>
                <option value="ab 100 ha">ab 100 ha </option>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md={6} className="align-self-end">
            <Button
              variant="contained"
              style={styles.button}
              onClick={this.back}
            >
              Zurück
            </Button>
            <MyButton
              disabled={ this.areFieldsInvalid(values)}
              color="primary"
              variant="contained"
              style={styles.button}
              onClick={this.continue}
            >
              Weiter
            </MyButton>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const styles = {
  button: {
    margin: 15,
    backgroundColor: "#444;",
  },
}

export default FormUserDetails
