import React, { Component } from "react"
import Geosuggest from "react-geosuggest"
import { Row, Col } from "react-bootstrap"
import { useState } from "react"
import {
  Button,
  
  Typography,
  Input,
  Slider,
  InputAdornment,
} from "@material-ui/core/"

import { styled } from "@material-ui/styles"

import { red } from "@material-ui/core/colors"

const MySlider = styled(Slider)({
  color: "#12652f",
})

const MyButton = styled(Button)({
  backgroundColor: "#12652f",
  "&:hover": {
    background: "#218838",
  },
})

export class FormSearchCriteria extends Component {
  constructor(props) {
    super(props)


  }

  
  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  }

  render() {
    const {
      values,
      handleChange,
      handleSliderChange,
      onSuggestSelect,
    } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Lage der gesuchten Immobilie</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Wir bieten Ihnen Unterstützung an bei der Immobiliensuche nach
              landwirtschaftlichen Immobilien. Übersenden Sie uns hierzu Ihre
              Suchkriterien. Wir werden umgehend den Kontakt zu Ihnen aufnehmen,
              wenn wir eine passende Immobilie gefunden haben.
              <br />
              <strong>Vielen Dank!</strong>
            </p>
          
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-3">
            <Geosuggest
              placeholder="Standort suchen"
              label="Ort"
              queryDelay={1000}
              value={values.location}
              onSuggestSelect={onSuggestSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Typography gutterBottom>Umkreis in km</Typography>
          </Col>
          <Col xs={8}>
            <MySlider
              style={{ color: "#12652f" }}
              defaultValue={0}
              // getAriaValueText={values.locationRange}
              value={values.locationRange}
              aria-labelledby="discrete-slider"
              onChange={handleSliderChange("locationRange")}
              step={10}
              marks
              min={10}
              max={300}
            />
          </Col>
          <Col xs={4}>
            <Input
              fullWidth
              value={values.locationRange}
              margin="dense"
              onChange={handleSliderChange("locationRange")}
              endAdornment={<InputAdornment position="end">Km</InputAdornment>}
              inputProps={{
                step: 10,
                min: 0,
                max: 300,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md={6} className="align-self-end">
            <Button disabled variant="contained" style={styles.button}>
              Zurück
            </Button>
            <MyButton
              color="primary"
              variant="contained"
              style={styles.button}
              onClick={this.continue}
            >
              Weiter
            </MyButton>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const styles = {
  button: {
    margin: 15,
  },
}

export default FormSearchCriteria
