import React, { Component } from "react"
import FormUserDetails from "./FormUserDetails"
import FormSearchCriteria from "./FormSearchCriteria"
import FormPersonalDetails from "./FormPersonalDetails"
import Confirm from "./Confirm"
import Success from "./Success"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"

import { MobileStepper,Button } from "@material-ui/core"
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';





export class UserForm extends Component {
  


  state = {
    step: 0,
    values: {
      price: "",
      area: "",
      location: {},
      locationRange: 0,
      objectKPM: "",
      objectType: "",
      objectPrice: [20, 37],
      objectSize: [10000, 300000],
      objectReason: "",
      salutation: "",
      firstName: "",
      lastName: "",
      company: "",
      job: "",
      street: "",
      postalCode: "",
      city: "",
      phone: "",
      email: "",
    },
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state
    this.setState({
      step: step + 1,
    })
  }

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state
    this.setState({
      step: step - 1,
    })
  }

  // Handle fields change
  handleChange = input => e => {
    const value = e.target.value
    console.log("change", e.target)
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [input]: value,
      },
    }))
  }
  handleSliderChange = input => (event, value) => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [input]: value,
      },
    }))
  }

  onSuggestSelect = value => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        location: value ? value : undefined,
      },
    }))
  }

  getSteps = () => {
    return [
      "Lage der gesuchten Immobilie",
      "Suchkriterien",
      "Kontaktdaten",
      "Zusammenfassung",
      "Fertig",
    ]
  }
  renderActiveStep = (step, values) => {
    switch (step) {
      case 0:
        return (
          <FormSearchCriteria
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            handleSliderChange={this.handleSliderChange}
            onSuggestSelect={this.onSuggestSelect}
          />
        )
      case 1:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            handleSliderChange={this.handleSliderChange}
          />
        )
      case 2:
        return (
          <FormPersonalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case 3:
        return (
          <Confirm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
          />
        )
      case 4:
        return <Success />
    }
  }

  render() {
    const { step } = this.state
    const { values } = this.state
    const steps = this.getSteps()
    
    

    return (
      <>
        <Stepper  activeStep={step} alternativeLabel className="d-md-flex  d-none">
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        
        <div>
        
        {this.renderActiveStep(step, values)}
        </div>
        <MobileStepper className="text-center " classes={{dotActive: 'mDot', root: 'mStepper',dots:'mDots'}} styles={{maxWidth:"400px", backgroundColor:"green"}} steps={5} variant="dots" activeStep={step} alternativeLabel  position="static"
       
      
       >
         
           </MobileStepper>
      </>
    )
  }
}

export default UserForm
