import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import UserForm from "../components/UserForm"

const ContactForm = () => (
  <Layout>
    <Container fluid>
      <Row >
        <div className="col-12 col-lg-6 p-0  h-md-100" id="immobilien">
          <div className="logoarea pt-5 pb-5"></div>
        </div>

        <Col md={6} className="bg-white p-5 ">
          <Col>
            <UserForm />
          </Col>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactForm
