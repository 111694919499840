import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import {
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { styled } from "@material-ui/styles"
import { faFontAwesomeLogoFull } from "@fortawesome/free-solid-svg-icons"




const MyButton = styled(Button)({
  backgroundColor: "#12652f",
  "&:hover": {
    background: "#218838",
  },
})
export class Confirm extends Component {
  constructor(props){
    super(props)
    this.state = {isPrivacyChecked: false}
  }
  
  continue = e => {
    e.preventDefault()
    const GATEWAY_URL = 'https://xjqa1uyn46.execute-api.eu-west-1.amazonaws.com/Production';
    
    const { values } = this.props
 
    try {
      fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify({message:JSON.stringify(values)}),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
    } catch (error) {
      // handle server errors
    }
    this.props.nextStep()
  }

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  }
  

  handleCheckboxChange() {

    this.setState({
      isPrivacyChecked: !this.state.isPrivacyChecked
      
    })
   
    

  }

  render() {
    const { values } = this.props
  
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Ihre Angaben</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Bitte überprüfen Sie Ihre Daten auf ihre Richtigkeit. Nach
              Absenden der Anfrage, erhalten Sie eine Nachricht in Ihrem Email
              Postfach zur Bestätigung.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <List>
              <ListItem>
                <ListItemText primary="Anrede" secondary={values.salutation} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Vorname" secondary={values.firstName} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Nachname" secondary={values.lastName} />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Standort"
                  secondary={values.location.description}
                />
              </ListItem>
            </List>
          </Col>
          <Col>
            <List>
              <ListItem>
                <ListItemText
                  primary="Kaufen / Pachten / Mieten"
                  secondary={values.objectKPM}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Fläche" secondary={values.area} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Umkreis"
                  secondary={values.locationRange}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Preisvorstellung"
                  secondary={values.price}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Fläche in Hektar"
                  secondary={values.objectSize}
                />
              </ListItem>
            </List>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={()=>this.handleCheckboxChange()}
                  // checked={.checkedB}
                  // onChange={handleChange('checkedB')}
                  value="checkedB"
                  color="#12652f"
                />
              }
              
            /> <span><a target="_blank" style ={{fontWeight: "600", fontFamily: "Roboto"}}href ="/datenschutz">Datenschutzerklärung</a> zustimmen *</span>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormControlLabel
              control={
                <Checkbox
                  // checked={.checkedB}
                  // onChange={handleChange('checkedB')}
                  value="checkedB"
                  color="#12652f"
                />
              }
             
              
            /><span style ={{fontWeight: "500", fontFamily: "Roboto"}}>Gesuch veröffentlichen</span>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col md={6} className="align-self-end">
            <Button
              variant="contained"
              style={styles.button}
              onClick={this.back}
            >
              Zurück
            </Button>
            <MyButton
              disabled ={!this.state.isPrivacyChecked}
              color="primary"
              variant="contained"
              style={styles.button}
              onClick={this.continue}
            >
              Weiter
            </MyButton>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const styles = {
  button: {
    margin: 15,
  },
}

export default Confirm
