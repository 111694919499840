import React, { Component } from "react"

export class Success extends Component {
  continue = e => {
    e.preventDefault()
    // PROCESS FORM //
    this.props.nextStep()
  }

  back = e => {
    e.preventDefault()
    this.props.prevStep()
  }

  render() {
    return (
      <React.Fragment>
        <h1>Vielen Dank für Ihre Anfrage!</h1>
        <p>Wir werden uns in Kürze bei Ihnen melden</p>
      </React.Fragment>
    )
  }
}

export default Success
